import { connectWallet, getConnectedWallet, selectIsConnected } from './redux/slices/web3Slice';
import Dashboard from './components/Dashboard';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import "./App.css";
import styles from "./App.module.css";

function App() {
  const isWalletConnected = useSelector(selectIsConnected);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConnectedWallet());
  }, [dispatch]);

  function onConnectWalletClick() {
    dispatch(connectWallet());
  }

  const connectWalletMessage = (<div className={styles.hkHeadingPanel}>
    <h1>Please connect your wallet</h1>
    <Button variant="primary" className="me-2" onClick={onConnectWalletClick}>Connect Wallet</Button>
  </div>);
  const dashboard = (<Dashboard></Dashboard>)

  return (
    <div>
      <div className={styles.pageForeground}>
        <Navbar className={styles.hkNavBar}>
          <Container>
            <Navbar.Brand href="#">
              <img style={{maxWidth: "240px"}} src="https://herokitty.space/wp-content/uploads/cropped-logo-web.png" alt="Hero Kitty" />
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className={styles.mobileHide}>
                {(!isWalletConnected) ? 
                  (<Button variant="primary" className="me-2" onClick={onConnectWalletClick}>Connect Wallet</Button>) : null}
                <Button as="a" variant="warning" href="https://herokitty.space">Return to Website</Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col>
                {(!isWalletConnected) ? connectWalletMessage : dashboard}
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.pageBackground}>
        <div className={styles.pageBgImg}></div>
        <div className={styles.pageBgRemainder}></div>
      </div>
    </div>
  );
}

export default App;
