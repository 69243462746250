import { commify } from "@ethersproject/units";

export default function toFormattedNumber(number, decimalPlaces) {
    //var fixedNumberString = '' + number.toFixed(decimalPlaces);
    var commifiedString = number; //commify(fixedNumberString);

    if (!commifiedString || commifiedString.length == 0) {
        return '';
    }

    var dpIndex = commifiedString.indexOf('.');
    var outputDp = commifiedString.length - dpIndex - 1;

    if (outputDp > decimalPlaces) {
        commifiedString = commifiedString.substr(0, dpIndex + decimalPlaces + 1);
        dpIndex = commifiedString.indexOf('.');
        outputDp = commifiedString.length - dpIndex - 1;
    }

    while (outputDp < decimalPlaces) {
        commifiedString += '0';
        dpIndex = commifiedString.indexOf('.');
        outputDp = commifiedString.length - dpIndex - 1;
    }

    return commifiedString;
}