import { createSlice } from '@reduxjs/toolkit'
import { Contract, ethers } from "ethers";
import { formatUnits } from 'ethers/lib/utils';
import moment from 'moment';
//import { loadHiddenTokens, queryBalances } from './tokensSlice';

export const web3Slice = createSlice({
  name: 'web3',
  initialState: {
    isConnected: false,
    isError: false,
    walletAddress: '',
    heroKittyBalance: 0,
    allNodesRewards: 0,
    nodes: [],
    totalNodes: 0
  },
  reducers: {
    connected: (state, action) => {
      state.isConnected = true;
      state.walletAddress = action.payload;
    },
    errorOnConnection: state => {
      state.isConnected = false;
      state.isError = true;
    },
    updateHeroKittyBalance: (state, action) => {
      state.heroKittyBalance = action.payload;
    },
    updateNodesRewards: (state, action) => {
      state.allNodesRewards = action.payload;
    },
    updateNodes: (state, action) => {
      state.nodes = action.payload;
    },
    updateTotalNodes: (state, action) => {
      state.totalNodes = action.payload;
    }
  }
})

export const connectWallet = () => {
    return async (dispatch, getState) => {
        try {
            await window.ethereum.enable();
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            //dispatch(queryBalances());
        } catch (err) {
            dispatch(errorOnConnection());
        }
    }
}

export const getConnectedWallet = () => {
    return async (dispatch, getState) => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const walletAddress = await signer.getAddress();
            dispatch(connected(walletAddress));
            dispatch(getBalances());
            dispatch(getTotalNodes());
        } catch (err) {
        }
    }
}

export const getBalances = () => {
    return async (dispatch, getState) => {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const walletAddress = await signer.getAddress();

          const heroKittyAbi = ['function balanceOf(address) view returns (uint)'];
          const heroKittyAddress = '0xef31b4fEd4A8db1813Fc4E739Bb92Be1821A01C7';
          const nodeManagerAbi = [
            'function getAllNodes(address account) external view returns (tuple(uint creationTime, uint lastClaimTime)[])',
            'function getAllNodesRewards(address account) external view returns (uint256)',
            'function getNodeReward(address account, uint256 _creationTime) external view returns (uint256)'
          ];
          const nodeManagerAddress = '0x5eEAe8410c58df6d9BFa9be7f7cd993FdAA058e3';

          let heroKittyContract = new Contract(heroKittyAddress, heroKittyAbi, provider);
          let heroKittyBalance = await heroKittyContract.balanceOf(walletAddress);
          dispatch(updateHeroKittyBalance(formatUnits(heroKittyBalance, 18)));

          let nodeManagerContract = new Contract(nodeManagerAddress, nodeManagerAbi, provider);
          let nodeDetails = await nodeManagerContract.getAllNodes(walletAddress);

          let totalNodeRewards = await nodeManagerContract.getAllNodesRewards(walletAddress);
          dispatch(updateNodesRewards(formatUnits(totalNodeRewards, 18)));

          let nodesOutput = [];
          for (var i = 0; i < nodeDetails.length; i++) {
            let node = nodeDetails[i];
            let createTime = new Date(0);
            createTime.setUTCSeconds(node.creationTime.toString());

            let lastClaimTime = new Date(0);
            lastClaimTime.setUTCSeconds(node.lastClaimTime.toString());

            let nodeRewards = await nodeManagerContract.getNodeReward(walletAddress, node.creationTime.toString());

            nodesOutput.push({
              'createTimeRaw': node.creationTime.toString(),
              'createTime': moment(createTime).format("DD MMM YY, HH:mm"),
              'lastClaimTimeRaw': node.lastClaimTime.toString(),
              'lastClaimTime': moment(lastClaimTime).format("DD MMM YY, HH:mm"),
              'rewards': formatUnits(nodeRewards, 18)
            });
          }

          dispatch(updateNodes(nodesOutput));
        } catch (err) {
          console.error(err);
        }
    }
}

export const getTotalNodes = () => {
  return async (dispatch, getState) => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        const nodeManagerAbi = ['function totalNodesCreated() public view returns (uint256)'];
        const nodeManagerAddress = '0x5eEAe8410c58df6d9BFa9be7f7cd993FdAA058e3';

        let nodeManagerContract = new Contract(nodeManagerAddress, nodeManagerAbi, provider);
        let totalNodes = await nodeManagerContract.totalNodesCreated();

        dispatch(updateTotalNodes(totalNodes));
      } catch (err) {
        console.error(err);
      }
  }
}

export const { connected, errorOnConnection, updateHeroKittyBalance, updateNodesRewards, updateNodes, updateTotalNodes } = web3Slice.actions

export const selectIsConnected = state => state.web3.isConnected
export const selectIsError = state => state.web3.isError
export const selectWalletAddress = state => state.web3.walletAddress
export const selectHeroKittyBalance = state => state.web3.heroKittyBalance
export const selectTotalNodesRewards = state => state.web3.allNodesRewards
export const selectNodes = state => state.web3.nodes
export const selectTotalNodes = state => state.web3.totalNodes

export default web3Slice.reducer