import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { parseUnits } from 'ethers/lib/utils';
import styles from "../App.module.css";
import toFormattedNumber from '../util/toFormattedNumber';

function Node({ cashoutCallback, index, nodeData }) {
    function onCashoutClick() {
        cashoutCallback(nodeData.createTimeRaw);
    }

    return (
        <Col xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
            <div className={styles.hkNode}>
                <h3>Node #{index}</h3>
                <p>Created: {nodeData.createTime}</p>
                <p>Last Claimed: {nodeData.lastClaimTime}</p>
                <p>Rewards: {toFormattedNumber(nodeData.rewards, 2)}</p>
                <div className={styles.hkNodeButtons}>
                    <Button
                        variant="success"
                        disabled={!parseUnits(nodeData.rewards, 18).gt(0)}
                        size="sm"
                        onClick={onCashoutClick}>Claim Rewards</Button>
                </div>
            </div>
        </Col>
    )
}

export default Node;
