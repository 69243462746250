import { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from "react-redux";
import { getBalances, selectHeroKittyBalance, selectNodes, selectTotalNodes, selectTotalNodesRewards } from "../redux/slices/web3Slice";
import useInterval from "../util/useInterval";
import Node from "./Node";
import styles from "../App.module.css";
import toFormattedNumber from "../util/toFormattedNumber";
import { parseUnits } from "ethers/lib/utils";
import { Contract } from "ethers";
import { Web3Provider } from "@ethersproject/providers";

function Dashboard() {
    const heroKittyAddress = '0xef31b4fEd4A8db1813Fc4E739Bb92Be1821A01C7';
    const hkAbi = [
        'function createNodeWithTokens() external',
        'function cashoutReward(uint256 blocktime) external',
        'function cashoutAll() external'
    ];

    const heroKittyBalance = '' + useSelector(selectHeroKittyBalance);
    const totalNodesRewards = '' + useSelector(selectTotalNodesRewards);
    const totalNodes = '' + useSelector(selectTotalNodes);
    const nodes = useSelector(selectNodes);
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getBalances());
    }, [dispatch]);

    useInterval(() => {
        dispatch(getBalances());
    }, 5000);

    async function onCreateNodeClick() {
        const provider = new Web3Provider(window.ethereum);
        const signer = provider.getSigner();
    
        let heroKittyContract = new Contract(heroKittyAddress, hkAbi, signer);
        await heroKittyContract.createNodeWithTokens();
        dispatch(getBalances());
    }

    async function onCashoutNodeClick(timestamp) {
        const provider = new Web3Provider(window.ethereum);
        const signer = provider.getSigner();
    
        let heroKittyContract = new Contract(heroKittyAddress, hkAbi, signer);
        await heroKittyContract.cashoutReward(timestamp);
        dispatch(getBalances());
    }

    async function onCashoutAllClick(timestamp) {
        const provider = new Web3Provider(window.ethereum);
        const signer = provider.getSigner();
    
        let heroKittyContract = new Contract(heroKittyAddress, hkAbi, signer);
        await heroKittyContract.cashoutAll();
        dispatch(getBalances());
    }

    const nodeCards = nodes.map((node, index) => <Node cashoutCallback={onCashoutNodeClick} nodeData={node} index={index + 1} key={index} />);

    return (
        <>
            <div className={styles.hkHeadingPanel}>
                <h1>HeroKitty Nodes Dashboard</h1>
                <Row className="mt-lg-4">
                    <Col xs="12" md={{span: 8, offset: 2}} lg={{span: 6, offset: 0}} xl={{span: 5, offset: 1}} xxl={{span: 4, offset: 2}} className="d-flex flex-column align-items-stretch justify-content-center mb-4 mb-lg-0">
                        <div className="d-flex align-items-center justify-content-stretch">
                            <div className="flex-grow-1 text-start me-2">Your HRKT Balance:</div>
                            <div className="text-right"><strong>{toFormattedNumber(heroKittyBalance, 2)}</strong></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-stretch">
                            <div className="flex-grow-1 text-start me-2">Your Rewards to Claim:</div>
                            <div className="text-right"><strong>{toFormattedNumber(totalNodesRewards, 2)}</strong></div>
                        </div>
                        <div className="mt-4 d-flex align-items-center justify-content-stretch">
                            <div className="flex-grow-1 text-start me-2">Total Active Nodes:</div>
                            <div className="text-right"><strong>{totalNodes}</strong></div>
                        </div>

                    </Col>
                    <Col xs="12" lg="6" className="d-flex align-items-center justify-content-center">
                        <Button
                            variant="success"
                            disabled={!parseUnits(totalNodesRewards, 18).gt(0)}
                            size="lg"
                            onClick={onCashoutAllClick}>Claim all Rewards</Button>
                    </Col>
                </Row>
            </div>
            <div className={styles.hkNodesPanel}>
                <Row className="text-center mb-5">
                    <Col>
                        <p>Nodes cost <strong>5000 HRKT</strong> to create and return <strong>4% per day</strong>, for a ROI time of <strong>25 days</strong>.</p>
                        <p><strong>95%</strong> of the cost goes directly to the node rewards pool, the other <strong>5%</strong> goes to the team for use in marketing and future development.</p>
                        <p>Each wallet may create up to <strong>50 nodes</strong>.</p>
                        <Button variant="success" disabled={!parseUnits(heroKittyBalance, 18).gt(0) || nodeCards.length >= 50} size="lg" onClick={onCreateNodeClick}>Create Node</Button>
                    </Col>
                </Row>
                <Row>
                    {nodeCards}
                </Row>
            </div>
        </>
    )
}

export default Dashboard;
